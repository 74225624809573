import { useSyncExternalStore, useMemo, useEffect, useRef, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux" // eslint-disable-line
import type { AppDispatch, RootState } from "store/store"
import { TKey } from "../i18n"
import { useLocation, useParams } from "react-router-dom" // eslint-disable-line
import {
    currencySelector,
    userBannedFromSocialSelector,
    userRoleSelector,
    userUIDSelector,
} from "store/appState"
import { Role } from "@constants/Role"
import isEqual from "lodash/isEqual"
import { cachedBuildingSelector } from "store/cache"
import { useMediaQuery } from "@mantine/hooks"
import { Formatter } from "@shared/formatter"
import fr from "../locales/fr/fr"
import en from "../locales/en/en"
import { LocalizedString } from "@appnflat-types/types"
import { FeatureFlags } from "@shared/FeatureFlags"

type DispatchFunc = () => AppDispatch
export const useAppDispatch: DispatchFunc = useDispatch

export function useAppSelector<TState extends RootState, TSelected>(
    selector: (state: TState) => TSelected
): TSelected {
    return useSelector(selector, isEqual)
}

/** Returns the most recent fiscal year of the building. */
export function useMostRecentFiscalYear() {
    const building = useAppSelector(cachedBuildingSelector)
    return building?.fiscalYears.length ? Math.max(...building.fiscalYears) : undefined
}

/** The languages the app can handle. */
export const supportedLanguages = ["fr", "en"] as const

/** Returns the current locale of the app.
 *
 * Note: in most cases, {@link useAppLanguage} should be used instead.
 */
export function useAppLocale() {
    return useSyncExternalStore(
        (cb) => {
            window.addEventListener("languagechange", cb)
            return () => window.removeEventListener("languagechange", cb)
        },
        () => {
            // For tests, use english locale.
            if (process.env.IS_TEST) return "en-US"
            else if (supportedLanguages.some((l) => navigator.language.startsWith(l)))
                return navigator.language
            else return "en"
        }
    )
}

/** Returns the current language of the app. */
export function useAppLanguage() {
    return useSyncExternalStore(
        (cb) => {
            window.addEventListener("languagechange", cb)
            return () => window.removeEventListener("languagechange", cb)
        },
        () => {
            // For tests, use english locale.
            if (process.env.IS_TEST) return "en"
            const matchedSupportedLanguage = supportedLanguages.find((l) =>
                navigator.language.startsWith(l)
            )
            if (matchedSupportedLanguage) return matchedSupportedLanguage
            else return "en"
        }
    )
}

export function getAppLanguage() {
    // For tests, use english locale.
    if (process.env.IS_TEST) return "en"
    const matchedSupportedLanguage = supportedLanguages.find((l) =>
        navigator.language.startsWith(l)
    )
    if (matchedSupportedLanguage) return matchedSupportedLanguage
    else return "en"
}

export function useAppFormatter() {
    const locale = useAppLocale()
    const currency = useAppSelector(currencySelector)
    return useMemo(() => new Formatter(locale, currency), [locale, currency])
}

export function useFeatureFlags() {
    const building = useAppSelector(cachedBuildingSelector)
    return useMemo(() => (building ? new FeatureFlags(building) : null), [building])
}

const locales = { fr, en }

type TOpts = Record<string, string | undefined>
type T$Opts = Record<`$${string}`, string | undefined>
/** Type of the `t` function used for translations. */
export type TFunction = (tKey: TKey, opts?: TOpts) => string

/** Returns a function to translate keys to the user's locale. */
export function useAppTranslation(): TFunction
export function useAppTranslation<T extends string>(localTranslations?: {
    [key in T]: LocalizedString
}): TFunction & ((tKey: T, opts?: T$Opts) => string)
export function useAppTranslation<T extends string>(localTranslations?: {
    [key in T]: LocalizedString
}) {
    const language = useAppLanguage()
    return useCallback(
        (tKey: TKey | T, opts?: TOpts) => {
            if (!tKey) return ""
            if (tKey.includes(":")) {
                const [group, key] = tKey.split(":")
                if (!key) return tKey
                const _translations: Record<
                    string,
                    Record<string, string | undefined> | undefined
                > = locales[process.env.IS_TEST ? "en" : language]
                let translation =
                    group && key && group in _translations && _translations[group] ?
                        (_translations[group]?.[key] ?? key)
                    :   key
                if (!translation) return key
                const options = Object.entries(opts ?? {})
                for (let i = 0, n = options.length; i < n; i++) {
                    const [option, value] = options[i] ?? []
                    if (!option || !value) continue
                    translation = translation.replace(new RegExp(`{{${option}}}`, "g"), value)
                }
                return translation
            } else {
                if (!localTranslations) return tKey
                const _translations = localTranslations[tKey as T]
                if (!_translations) return tKey
                let translation = _translations[language]
                if (!translation) return tKey
                const options = Object.entries(opts ?? {})
                for (let i = 0, n = options.length; i < n; i++) {
                    const [option, value] = options[i] ?? []
                    if (!option || !value) continue
                    translation = translation.replace(
                        RegExp(option.replace("$", "\\$"), "g"),
                        value ?? ""
                    )
                }
                return translation
            }
        },
        [language, localTranslations, locales]
    )
}

/** Returns whether the viewport is currently sized as a mobile device. */
export function useIsMobile() {
    return !!useMediaQuery("(max-width: 768px)") // 48em
}

/** Typed version of useParams(). */
export function useAppParams(): {
    buildingRef?: string
    fiscalYear?: number
    unitUUID?: string
    supplierUUID?: string
    bankUUID?: string
    parkingUUID?: string
    lockerUUID?: string
    categoryUUID?: string
    personUUID?: string
} {
    const params = useParams()
    const fiscalYearAsNumber = Number(params.fiscalYear)
    return {
        ...params,
        fiscalYear: Number.isNaN(fiscalYearAsNumber) ? undefined : fiscalYearAsNumber,
    }
}

/** A custom hook that builds on useLocation to parse the query string for you. */
export function useQuery() {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

/** A custom hook that returns information on the permissions of the user for the building. */
export function usePermissions() {
    const userUID = useAppSelector(userUIDSelector)
    const userRole = useAppSelector(userRoleSelector)
    const isBanned = useAppSelector(userBannedFromSocialSelector)
    const building = useAppSelector(cachedBuildingSelector)
    const isAdmin = !!userRole && [Role.approver, Role.admin].includes(userRole)
    const isAccountant = !!userRole && [Role.accountantWrite, Role.accountant].includes(userRole)
    const isOwnerOrResident = !!userRole && [Role.owner, Role.resident].includes(userRole)

    return useMemo(
        () => ({
            /** The uid of the user. */
            userUID,
            /** The role of the user. */
            userRole,
            /** Whether the user is banned. */
            isBanned,
            /** The user has either admin or approver roles. */
            isAdmin,
            /** The user has either accountant or accountant with write roles. */
            isAccountant,
            /** The user has either owner or resident roles. */
            isOwnerOrResident,
            /** The user is an owner with limited financial read abilities (suppliers, banks, but not people, payments, etc.) */
            hasOwnerFinancialAccessRead:
                userRole === Role.owner && building?.ownersCanAccessFinances,
            /** The user can read to all financial objects (units, suppliers, budgets, people, payments etc.) */
            hasFinancialAccessRead: isAdmin || isAccountant,
            /** The user can write to all financial objects (units, suppliers, budgets, etc.) */
            hasFinancialAccessWrite: isAdmin || userRole === Role.accountantWrite,
            hasSocialAccessRead: isAdmin || isOwnerOrResident,
            hasSocialAccessWrite: isAdmin || (isOwnerOrResident && !isBanned),
        }),
        [userRole]
    )
}

/**
 * Hook to dynamically set focus on an element.
 * Returns:
 * - The ref to attach to the element,
 * - A function to set focus on the element to which the ref is attached,
 * - A function to remove focus from the element to which the ref is attached.
 */
export function useFocus<T extends HTMLElement = HTMLElement>() {
    const ref = useRef<T>(null)
    const setFocus = () => ref?.current?.focus?.()
    const unsetFocus = () => ref?.current?.blur?.()
    return [ref, setFocus, unsetFocus] as const
}

/**
 * All the valid key codes that the browser can provide (this is not an exhaustive list and
 * can be updated if need be).
 */
export type KeyboardKey =
    | " "
    | "Enter"
    | "Tab"
    | `Arrow${"Down" | "Up" | "Left" | "Right"}`
    | "Backspace"
    | "Escape"
    | "0"
    | "1"
    | "2"
    | "3"
    | "4"
    | "5"
    | "6"
    | "7"
    | "8"
    | "9"
    | "a"
    | "b"
    | "c"
    | "d"
    | "e"
    | "f"
    | "g"
    | "h"
    | "i"
    | "j"
    | "k"
    | "l"
    | "m"
    | "n"
    | "o"
    | "p"
    | "q"
    | "r"
    | "s"
    | "t"
    | "u"
    | "v"
    | "w"
    | "x"
    | "y"
    | "z"
    | "/"
    | "."
    | ","
    | "?"
    | "["
    | "]"

/**
 * Allows capture of key entries. Will not capture keys when they are entered on an element other
 * than a button or the body, unless `modifier == "CmdOrCtrl" || key == "Escape"`.
 */
export function useKey(
    modifier: "CmdOrCtrl" | "Shift" | null,
    key: KeyboardKey,
    cb: (() => void) | undefined,
    /** A function that returns whether or not we should execute the callback. */
    conditionalExecution?: () => boolean
) {
    const callback = useRef(cb)
    // Do not remove the `{}`, it will cause the callback to be called immediately.
    useEffect(() => {
        callback.current = cb
    })
    useEffect(() => {
        function handle(event: KeyboardEvent) {
            const isInput =
                !!event &&
                !!event.target &&
                "nodeName" in event.target &&
                typeof event.target.nodeName === "string" &&
                !["body", "button", "div"].includes(event.target.nodeName.toLowerCase())
            if (isInput && modifier !== "CmdOrCtrl" && key !== "Escape") return
            function executeCallBack() {
                if (conditionalExecution && !conditionalExecution()) return
                event.preventDefault()
                callback.current?.()
            }
            if (modifier && event.key === key) {
                if (modifier === "CmdOrCtrl" && (event.ctrlKey || event.metaKey)) executeCallBack()
                else if (modifier === "Shift" && event.shiftKey) executeCallBack()
            } else if (!modifier && event.key === key) {
                executeCallBack()
            }
        }
        document.addEventListener("keydown", handle)
        return () => document.removeEventListener("keydown", handle)
    }, [key])
}
