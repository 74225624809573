import { auth } from "firebaseSetup"
import { showErrorNotification } from "logic/notifications"
import createClient, { Middleware } from "openapi-fetch"
import { paths } from "openapi/backend"
import { baseAPIUrl } from "./baseURL"
import { SimpleTKey } from "logic/textDescriptions/translate"
import { LocalizedString } from "@appnflat-types/types"
import { store } from "store/store"
import { PathWithQuery, queryCacheSelector, setQueryCacheEntry } from "store/queryCache"
import { DateTime, httpDateSchema } from "@shared/dates"

const client = createClient<paths>({ baseUrl: baseAPIUrl })

function contentType(body: any): "application/json" | "multipart/form-data" | undefined {
    if (!body || typeof body !== "object") return undefined
    try {
        const stringified = JSON.stringify(body)
        if (stringified.startsWith("{") || stringified.startsWith("[")) return "application/json"
        else return undefined
    } catch {
        return undefined
    }
}

function urlToCacheKey(url: string) {
    return url.replace(baseAPIUrl, "") as PathWithQuery
}

const requestMiddleware: Middleware = {
    onRequest: async function onRequest({ request }) {
        // Add the Authorization header to the request
        const token = await auth.currentUser?.getIdToken()
        request.headers.set("Authorization", `Bearer ${token}`)

        // Set the Content-Type header if it is not set
        if (request.method === "POST" || request.method === "PUT") {
            const type = contentType(request.body)
            if (type) request.headers.set("Content-Type", type)
        }

        // If the request has a If-Modified-Since header, we should check if we have the data
        // in the cache and if not, we should remove the header. Otherwise, we might get a 304
        // response when we should get a 200 response.
        if (request.headers.has("If-Modified-Since")) {
            const cachedEntry = queryCacheSelector(urlToCacheKey(request.url))(store.getState())
            if (!cachedEntry) request.headers.delete("If-Modified-Since")
        }

        return request
    },

    onResponse: async function onResponse({ response, request }) {
        const { body, ...resOptions } = response

        // If the response is 304, we should return the cached data
        if (response.status === 304) {
            const cachedEntry = queryCacheSelector(urlToCacheKey(request.url))(store.getState())
            if (cachedEntry) return new Response(JSON.stringify(cachedEntry), resOptions)
        }

        if (response.headers.has("Last-Modified") && response.status === 200) {
            // If the request has a If-Modified-Since header and the response is 200, we should cache the data
            const lastModifiedHeader = httpDateSchema.safeParse(
                response.headers.get("Last-Modified")
            )
            if (lastModifiedHeader.success) {
                const data = await response.clone().json()
                store.dispatch(
                    setQueryCacheEntry({
                        path: urlToCacheKey(request.url),
                        response: data,
                        lastModified: new DateTime(lastModifiedHeader.data).toSeconds(),
                        lastUsed: new DateTime("now").toSeconds(),
                    })
                )
            }
        }

        return undefined
    },
}

client.use(requestMiddleware)
export const { GET, POST, PUT, DELETE } = client

export function requestErrorHandler(
    errorTitle: SimpleTKey | "silentError" | LocalizedString,
    onError?: (error: any) => void
) {
    return function _errorHandler(error: any) {
        console.error(`Caught error in request: `, error)
        if (errorTitle !== "silentError") showErrorNotification({ title: errorTitle }, error)
        if (onError) onError(error)
    }
}

export function requestResultHandler(errorTitle: SimpleTKey | "silentError" | LocalizedString) {
    return function _defaultHandler<T extends object | void>(result: T): T {
        if (result && "error" in result && result.error) {
            console.error(`Caught error in request: `, result.error)
            if (errorTitle !== "silentError")
                showErrorNotification({ title: errorTitle }, result.error)
        }
        return result
    }
}

export async function requestHandler<R extends object | void, E extends object | void>({
    request,
    errorTitle,
    onError,
    onResult,
    onFinally,
}: {
    request: Promise<{ data?: R; error?: E }>
    errorTitle: SimpleTKey | "silentError" | LocalizedString
    onError?: (error: E | undefined | null) => void
    onResult?: (result: R) => void
    onFinally?: () => void
}) {
    const result = await request.catch((error) => {
        onError?.(error)
        requestErrorHandler(errorTitle, onError)(error)
        onFinally?.()
    })
    console.log("Result of request: ", result)
    if (!result) {
        onFinally?.()
        return
    }
    if ("error" in result && result.error) {
        onError?.(result.error)
        requestResultHandler(errorTitle)(result)
    }
    if ("data" in result && result.data) onResult?.(result.data)
    onFinally?.()
    return result
}
