import { z } from "zod"

export const dbLockSchema = z.object({
    name: z.enum(["transactions", "send-to-otonom"]),
    /** An array of processes queueing to get the lock once it has been released, sorted by date. */
    queue: z
        .array(
            z.object({
                processId: z.string(),
                /** The time at which this process will get start considering the lock is owned by it. */
                acquisitionDate: z.number(),
            })
        )
        .transform((queue) => queue.sort((a, b) => a.acquisitionDate - b.acquisitionDate)),
})
export type DBLock = z.infer<typeof dbLockSchema>
