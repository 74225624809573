import { Building } from "../@appnflat-types/Building"
import { Transaction } from "../@appnflat-types/Transaction"
import { Role } from "../@constants/Role"

export class FeatureFlags {
    protected building: Building

    constructor(building: Building) {
        this.building = building
    }

    /** Whether the building is integrated with Otonom. */
    public get otonom(): boolean {
        return !!this.building.otonomId
    }

    /** Whether the building should automatically send transactions of type `supplierPayment` and `recurringBillPayment` to Otonom. */
    public get automaticallySendToOtonom(): boolean {
        return this.otonom && !!this.building.otonomAutomaticallySend
    }

    /** Whether transactions can be approved. */
    public get approval(): boolean {
        return !!this.building.numberOfApprovalsRequired
    }

    /** Whether a transaction requires approval. */
    public transactionRequiresApproval(transaction: Transaction): boolean {
        return (
            this.approval &&
            transaction.kind === "supplierPayment" &&
            !!this.building.paymentMethodsRequiringApproval?.length &&
            !!transaction.paymentMethod &&
            this.building.paymentMethodsRequiringApproval.includes(transaction.paymentMethod)
        )
    }

    /** Returns a list of the roles in the building that receive alerts when certain automated operations fail. */
    public get rolesThatReceiveAlerts(): [Role.admin] | [Role.admin, Role.approver] {
        if (this.building.rolesThatReceiveAlerts?.includes(Role.approver)) {
            return [Role.admin, Role.approver]
        } else {
            return [Role.admin]
        }
    }
}
