import React from "react"
import { useNavigate } from "react-router-dom"
import {
    useAppParams,
    useAppTranslation,
    useFeatureFlags,
    useIsMobile,
    usePermissions,
} from "hooks/hooks"
import { TKey } from "i18n"
import { Button, Menu, Tabs } from "@mantine/core"
import { filterNullUndefinedFalse } from "@shared/arrays"
import * as classes from "./SecondaryNavBar.module.css"
import {
    IconBuilding,
    IconBuildingBank,
    IconCalendarTime,
    IconCash,
    IconChecklist,
    IconChevronDown,
    IconClock,
    IconCoins,
    IconDiscountCheck,
    IconDoor,
    IconFile,
    IconFileChart,
    IconFileDollar,
    IconGavel,
    IconLocationDollar,
    IconLock,
    IconMail,
    IconMoneybag,
    IconNumbers,
    IconReportAnalytics,
    IconTag,
    IconTool,
    IconTransfer,
    IconUser,
    IconUserCog,
    IconUsers,
    IconUsersGroup,
} from "@tabler/icons-react"
import "@mantine/core/styles/Tabs.layer.css"

export function Component() {
    const t = useAppTranslation()
    const {
        buildingRef,
        fiscalYear,
        unitUUID,
        supplierUUID,
        bankUUID,
        categoryUUID,
        personUUID,
        lockerUUID,
    } = useAppParams()
    const featureFlags = useFeatureFlags()
    const { isAdmin, isAccountant, hasFinancialAccessWrite } = usePermissions()
    const isMobile = useIsMobile()
    const navigate = useNavigate()
    const pathSegment = (window.location
        .toString()
        .match(/\/building\/[^/?]+\/[^/?]+\/([^/?]*|)/) ?? [])[1]
    const selectedValue = (window.location.toString().match(/\/building(\/[^/?]+){4}(\/[^/?]*|)/) ??
        [])[0]

    const segmentToPath = (segment: string, uuid?: string): `/building/${string}` =>
        `/building/${filterNullUndefinedFalse([
            buildingRef,
            fiscalYear,
            pathSegment,
            segment,
            uuid,
        ]).join("/")}`

    let options: {
        label: TKey
        path: `/building/${string}`
        Icon: typeof IconBuilding
    }[] = []
    switch (pathSegment) {
        case "units":
            options = [
                {
                    label: "core:transactions",
                    path: segmentToPath("transactions", unitUUID),
                    Icon: IconCoins,
                },
                { label: "core:unit", path: segmentToPath("info", unitUUID), Icon: IconDoor },
                ...(isAdmin || isAccountant ?
                    [
                        {
                            label: "core:penalties_and_fees" as const,
                            path: segmentToPath("penalties"),
                            Icon: IconGavel,
                        },
                        {
                            label: "core:reports" as const,
                            path: segmentToPath("reports"),
                            Icon: IconFile,
                        },
                    ]
                :   []),
            ]
            break
        case "people":
            options = [
                {
                    label: "core:owners_and_residents",
                    path: segmentToPath("info", personUUID),
                    Icon: IconUsers,
                },
                ...(isAdmin || isAccountant ?
                    [
                        {
                            label: "core:reports" as const,
                            path: segmentToPath("reports"),
                            Icon: IconFile,
                        },
                    ]
                :   []),
            ]
            break
        case "suppliers":
            options = [
                {
                    label: "core:transactions",
                    path: segmentToPath("transactions", supplierUUID),
                    Icon: IconCoins,
                },
                {
                    label: "core:suppliers",
                    path: segmentToPath("info", supplierUUID),
                    Icon: IconTool,
                },
                ...(isAdmin || isAccountant ?
                    [
                        {
                            label: "core:reports" as const,
                            path: segmentToPath("reports"),
                            Icon: IconFile,
                        },
                    ]
                :   []),
            ]
            break
        case "banks":
            options = [
                {
                    label: "core:bank_statement",
                    path: segmentToPath("transactions", bankUUID),
                    Icon: IconFileDollar,
                },
                {
                    label: "core:bank_accounts",
                    path: segmentToPath("info", bankUUID),
                    Icon: IconBuildingBank,
                },
                ...(hasFinancialAccessWrite ?
                    [
                        {
                            label: "core:bank_reconciliation" as const,
                            path: segmentToPath("reconciliation"),
                            Icon: IconChecklist,
                        },
                    ]
                :   []),
                ...(isAdmin || isAccountant ?
                    [
                        {
                            label: "core:reports" as const,
                            path: segmentToPath("reports"),
                            Icon: IconFile,
                        },
                    ]
                :   []),
            ]
            break
        case "categories":
            options = [
                {
                    label: "core:transactions",
                    path: segmentToPath("transactions", categoryUUID),
                    Icon: IconCoins,
                },
                {
                    label: "core:accounting_categories",
                    path: segmentToPath("info", categoryUUID),
                    Icon: IconTag,
                },
                { label: "core:budget", path: segmentToPath("budget"), Icon: IconReportAnalytics },
                ...(hasFinancialAccessWrite ?
                    [
                        {
                            label: "core:fiscal_year" as const,
                            path: segmentToPath("fiscal-year"),
                            Icon: IconCalendarTime,
                        },
                    ]
                :   []),
                ...(isAdmin || isAccountant ?
                    [
                        {
                            label: "core:accountant_writings" as const,
                            path: segmentToPath("accountant-writings"),
                            Icon: IconNumbers,
                        },
                        {
                            label: "core:financial_statements" as const,
                            path: segmentToPath("financial-statement"),
                            Icon: IconFileChart,
                        },
                        {
                            label: "core:reports" as const,
                            path: segmentToPath("reports"),
                            Icon: IconFile,
                        },
                    ]
                :   []),
            ]
            break
        case "admin":
            options = [
                { label: "core:building", path: segmentToPath("building"), Icon: IconBuilding },
                ...(isAdmin ?
                    [
                        {
                            label: "core:users" as const,
                            path: segmentToPath("users"),
                            Icon: IconUsersGroup,
                        },
                    ]
                :   []),
                {
                    label: "core:lockers",
                    path: segmentToPath("lockers", lockerUUID),
                    Icon: IconLock,
                },
            ]
            break
        case "posts":
            options = [
                { label: "core:all_posts", path: segmentToPath("all"), Icon: IconUsersGroup },
                { label: "core:administration", path: segmentToPath("admin"), Icon: IconUserCog },
                { label: "core:my_posts", path: segmentToPath("my"), Icon: IconUser },
                ...(isAdmin ?
                    [
                        {
                            label: "core:automatic_communications" as const,
                            path: segmentToPath("reminders"),
                            Icon: IconClock,
                        },
                        {
                            label: "core:email_templates" as const,
                            path: segmentToPath("emails"),
                            Icon: IconMail,
                        },
                    ]
                :   []),
            ]
            break
        case "payments":
            options = [
                { label: "core:checks", path: segmentToPath("checks"), Icon: IconCash },
                { label: "core:deposits", path: segmentToPath("deposits"), Icon: IconMoneybag },
                {
                    label: "core:transfers",
                    path: segmentToPath("wire-transfers"),
                    Icon: IconLocationDollar,
                },
                ...(isAdmin && featureFlags?.approval ?
                    [
                        {
                            label: "core:approval" as const,
                            path: segmentToPath("approval"),
                            Icon: IconDiscountCheck,
                        },
                    ]
                :   []),
                ...(featureFlags?.otonom ?
                    [
                        {
                            label: "core:otonom_solution" as const,
                            path: segmentToPath("otonom"),
                            Icon: IconTransfer,
                        },
                    ]
                :   []),
                ...(isAdmin || isAccountant ?
                    [
                        {
                            label: "core:reports" as const,
                            path: segmentToPath("reports"),
                            Icon: IconFile,
                        },
                    ]
                :   []),
            ]
            break
        default:
            return undefined
    }

    if (isMobile)
        return (
            <Menu>
                <Menu.Target>
                    <Button leftSection={<IconChevronDown />}>
                        {t(options.find((o) => o.path === selectedValue)?.label ?? "")}
                    </Button>
                </Menu.Target>
                <Menu.Dropdown>
                    {options.map(({ path, Icon, label }) => (
                        <Menu.Item
                            classNames={{ itemLabel: classes.itemLabel }}
                            key={path}
                            onClick={() => navigate(path)}
                            data-active={path === selectedValue || undefined}
                        >
                            <Icon width={15} height={15} />
                            {t(label)}
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
        )
    else
        return (
            <Tabs
                value={selectedValue}
                onChange={(v) => navigate(v ?? "")}
                classNames={{ ...classes, root: "secondary-navbar-container" }}
                variant="outline"
            >
                <Tabs.List justify="center">
                    {options.map(({ path, Icon, label }) => (
                        <Tabs.Tab
                            leftSection={<Icon width={15} height={15} />}
                            key={path}
                            value={path}
                        >
                            {t(label)}
                        </Tabs.Tab>
                    ))}
                </Tabs.List>
            </Tabs>
        )
}
Component.displayName = "SecondaryNavBar"
